
// @font-face{font-family:Nunito;src:url(assets/font/Nunito-Regular.ttf);src:url(assets/font/Nunito-Regular.ttf) format('ttf'),url(assets/font/Nunito-Regular.ttf) format('truetype')}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

// *{
//   transition: all 0.3s ease-in;
// }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.box-shadow-light{
  box-shadow: 0px 5px 5px -5px #CCC;
}

.box-shadow-light:hover{
  box-shadow: 0px 2.5px 10px 0px #CCC;
}

.box-shadow-dark{
  box-shadow: 0px 5px 5px -5px rgba(0, 53, 102,0.5);
}

.box-shadow-dark:hover{
  box-shadow: 0px 2.5px 10px 0px rgba(0, 53, 102, 0.7);
}

.light{
  color:#001529 !important;
}

.dark{
  color:#ffffff !important;
}

.small-icon{
  position: relative;
  top: -2.5px;
  font-size: 0.75rem;
}


// #root{
//   display: inline-block;
// }